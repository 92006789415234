import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createPinia } from "pinia";
import VueApexCharts from "vue3-apexcharts";
require("./registerServiceWorker");

export const host = process.env.VUE_APP_HOST as string;
const pinia = createPinia();

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

pinia.use(piniaPluginPersistedstate);

loadFonts();
const app = createApp(App);
app.use(VueApexCharts);
app.use(router);
app.use(vuetify);
app.use(pinia);

app.mount("#app");
