import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_SettingsTab = _resolveComponent("SettingsTab")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_app = _resolveComponent("v-app")!
  const _directive_scroll = _resolveDirective("scroll")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "ma-auto pa-auto" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_navigation_drawer, {
            float: "",
            onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rail = true)),
            ref: "navBarRef",
            rail: _ctx.rail,
            permanent: "",
            onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rail = false)),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeNav()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                class: _normalizeClass(_ctx.rail ? 'ma-3' : 'mx-auto my-3'),
                src: 
            _ctx.theme.current.value.dark
              ? '/img/Zirkel-white.png'
              : '/img/Zirkel.png'
          ,
                nav: "",
                contain: "",
                "max-width": "80"
              }, null, 8, ["class", "src"]),
              _createVNode(_component_v_divider),
              (!_ctx.isLoggedIn)
                ? (_openBlock(), _createBlock(_component_v_list, {
                    key: 0,
                    density: "compact",
                    nav: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-home-city",
                        title: "Home",
                        value: "home",
                        to: "/"
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.isLoggedIn)
                ? (_openBlock(), _createBlock(_component_v_list, {
                    key: 1,
                    density: "compact",
                    nav: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-home-city",
                        title: "Home",
                        value: "home",
                        to: "/"
                      }),
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-calendar",
                        title: "Calendar",
                        value: "account",
                        to: "/calendar"
                      }),
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-forum",
                        title: "Forum",
                        value: "Forum",
                        to: "/forum"
                      }),
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-contacts",
                        title: "Anschriften",
                        value: "Anschriften",
                        to: "/anschriften"
                      }),
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-firefox",
                        title: "Spefüxe",
                        value: "Spefüxe",
                        to: "/spefuexe"
                      }),
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-folder",
                        title: "Dateien",
                        value: "dateien",
                        to: "/dateien"
                      }),
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-camera-burst",
                        title: "Gallery",
                        value: "Gallery",
                        to: "/Gallery"
                      }),
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-poll",
                        title: "Abstimmungen",
                        value: "Abstimmungen",
                        to: "/Abstimmungen"
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_spacer),
              (_ctx.isLoggedIn)
                ? (_openBlock(), _createBlock(_component_v_list, {
                    key: 2,
                    nav: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SettingsTab),
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-logout",
                        title: _ctx.logoutString,
                        onClick: _ctx.logout
                      }, null, 8, ["title", "onClick"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.isLoggedIn)
                ? (_openBlock(), _createBlock(_component_v_list, {
                    key: 3,
                    nav: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        "prepend-icon": "mdi-login",
                        title: "Login",
                        to: "/login"
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_spacer),
              (_ctx.userDataStore.getId == '1')
                ? (_openBlock(), _createBlock(_component_v_list, { key: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, { disabled: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Admin Area")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, { to: "/Zeitstrahl" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Zeitstrahl")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, { to: "/Familien" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Familien")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, { to: "/admin" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Admin")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["rail"]),
          _createVNode(_component_v_main, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, null, {
            activator: _withCtx(({ props }) => [
              _withDirectives(_createVNode(_component_v_btn, _mergeProps(props, {
                class: "text-end ma-5",
                onClick: _ctx.toTop,
                position: "fixed",
                location: "bottom right",
                icon: "mdi-arrow-up",
                style: {"z-index":"2000"},
                color: "primary",
                elevation: "8"
              }), null, 16, ["onClick"]), [
                [_directive_scroll, _ctx.onScroll],
                [_vShow, _ctx.fab]
              ])
            ]),
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", null, "Zurück nach oben", 512), [
                [_vShow, _ctx.fab]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}