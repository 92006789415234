<template>
  <v-container>
    <v-card max-width="800" class="ma-auto my-3 d-flex flex-column">
      <v-card-title class="text-wrap">
        Es befindet sich aktuell im Umbau.
      </v-card-title>
      <v-card-text
        >Hier ensteht das neue System für die Prima Oldenburgensis</v-card-text
      >
    </v-card>
    <v-card max-width="800" class="ma-auto my-3 d-flex flex-column">
      <v-card-title>Forum</v-card-title>
      <v-card-text>Das alte Forum soll hierdurch abgelöst werden.</v-card-text>
    </v-card>
    <v-card max-width="800" class="ma-auto my-3 d-flex flex-column">
      <v-card-title>Kalender</v-card-title>
      <v-card-text
        >Zu den ACAC und sonstigen Veranstaltungen sollen hier Termine im
        Kalender verankert werden können, zu welchen jeder Primenser sich an-
        oder abmelden kann.</v-card-text
      >
    </v-card>
    <v-card max-width="800" class="ma-auto my-3 d-flex flex-column">
      <v-card-title>Spefüxe</v-card-title>
      <v-card-text
        >Den Alten Herren und Inaktiven soll hier die Möglichkeit gegeben
        werden, den Aktiven einfach Spefüxe melden zu können, auch wenn diese
        vielleicht noch nicht ganz im richtigen Alter sind. Zum Richtigen
        Zeitpunkt wird eine Errinnerung rausgeschickt.</v-card-text
      >
    </v-card>
    <v-card max-width="800" class="ma-auto my-3 d-flex flex-column">
      <v-card-title>Anschriftenverzeichnis</v-card-title>
      <v-card-text
        >Du hast gerade eine Adresse oder Telefonnummer nicht zur Hand oder
        möchtest, dass deine Conaktiven deine neue Adresse einfach finden
        können? Trage sie hier einfach in das Anschriftenverzeichnis
        ein!</v-card-text
      > </v-card
    ><v-card max-width="800" class="ma-auto my-3 d-flex flex-column">
      <v-card-title>Weitere Ideen?</v-card-title>
      <v-card-text
        >Du wirst die Entwickler dieser Plattform einfach erreichen können. Wir
        sind offen für Vorschläge zu neuen Funktionen oder zur Verbesserung
        bereits bestehender!</v-card-text
      >
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HelloWorld",

  data() {
    return {};
  },
});
</script>

<style>
.v-card {
  max-width: 500px;
}
</style>
