/* eslint-disable no-console */
import { register } from "register-service-worker";
import { useUserDataStore } from "./store/userDataStore";

if (process.env.NODE_ENV === "production") {
  register(`https://prima.oldenburgensis.de/service-worker.js`, {
    ready() {
      console.log("Service worker is active.");
    },
    registered(registration: ServiceWorkerRegistration) {
      console.log("Service worker has been registered.");
      subscribeUserToPush(registration);
      async function subscribeUserToPush(
        registration: ServiceWorkerRegistration
      ) {
        const applicationServerKey = urlB64ToUint8Array(
          "BHrnrFSIHezsOP7XjK2eNN9OmEymb448Heg2HKaxjSfNa3kJpIUUQl0Bo1aEMXoGLCVgK6fSsgU146OY20t8lXE"
        );
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey,
          })
          .then((subscription) => {
            console.log("User is subscribed:", subscription);
            const userDataStore = useUserDataStore();

            // Senden Sie das Abonnement an Ihren Server, um Push-Benachrichtigungen zu senden
            fetch(
              "https://prima.oldenburgensis.de/api/notifications/subscribe",
              {
                method: "POST",
                body: JSON.stringify({
                  subscription,
                  token: userDataStore.getToken,
                }),
                headers: {
                  "Content-Type": "application/json",
                  authorization: "Bearer " + userDataStore.getToken,
                },
              }
            );
          })
          .catch((err) => {
            console.log("Failed to subscribe the user: ", err);
          });
      }

      function urlB64ToUint8Array(base64String: string) {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, "+") // Entfernen des unnötigen Escape-Charakters
          .replace(/_/g, "/"); // Entfernen des unnötigen Escape-Charakters
        const rawData = self.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      }

      self.addEventListener("pushsubscriptionchange", function (event: any) {
        subscribeUserToPush(registration);
      });
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },

    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration: ServiceWorkerRegistration) {
      console.log("New content is available; please refresh.");
      registration.update();
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
