<template>
  <v-list-item
    prepend-icon="mdi-cog"
    title="Einstellungen"
    @click="settingsTab = true"
  ></v-list-item>
  <v-dialog max-width="600" v-model="settingsTab">
    <v-card class="justify-end text-center">
      <v-switch
        class="mx-auto"
        elevation="0"
        label="Dark Mode"
        @click="toggleTheme"
        prepend-icon="mdi-theme-light-dark"
        :value-comparator="() => isDarkMode"
      />
      <v-btn flat @click="passwortZuruecksetzten()"
        >Passwort zurücksetzen</v-btn
      >
      <v-dialog max-width="600">
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn
            flat
            v-bind="activatorProps"
            text="Benachrichtigungen einstellen"
            @click="getNotificationPreferences()"
          ></v-btn>
        </template>

        <template v-slot:default="{ isActive }">
          <v-card title="Benachrichtigungen">
            <v-card-text>
              <v-row
                ><v-switch
                  v-show="permissionSet"
                  label="Forum"
                  v-model="preferencesforum"
                ></v-switch
              ></v-row>
              <v-row
                ><v-switch
                  v-show="permissionSet"
                  label="Termine"
                  v-model="preferencescalendar"
                ></v-switch
              ></v-row>
              <v-row
                ><v-switch
                  v-show="permissionSet"
                  label="Terminzusagen"
                  v-model="preferencesanmeldungen"
                ></v-switch
              ></v-row>
              <v-row
                ><v-switch
                  v-show="permissionSet"
                  label="Terminabsagen"
                  v-model="preferencesabmeldungen"
                ></v-switch
              ></v-row>
              <v-row v-show="!permissionSet" class="justify-center">
                <v-btn @click="askPermissions()">Berechtigungen vergeben</v-btn>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                text="Abbrechen"
                @click="getNotificationPreferences(), (isActive.value = false)"
              ></v-btn>
              <v-btn
                color="error"
                variant="flat"
                @click="setNotificationPreferences(), (isActive.value = false)"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-card-actions class="justify-end">
        <v-btn color="disabled" to="/debug" @click="settingsTab = false"
          >Debug info</v-btn
        ><v-spacer></v-spacer>
        <v-btn @click="settingsTab = false">Schließen</v-btn>
      </v-card-actions></v-card
    >
  </v-dialog>
  <v-snackbar v-model="pwResetPositive">
    Ein Link zum Passwort zurücksetzen wurde nun an Deine angegebene
    Mail-Adresse geschickt. </v-snackbar
  ><v-snackbar v-model="pwResetNegative">
    Beim Passwort zurücksetzen trat ein Fehler auf. Bitte versuche es erneut
    oder wende Dich an die Administratoren.
  </v-snackbar>
</template>

<script lang="ts">
import { useUserDataStore } from "@/store/userDataStore";
import axios from "axios";
import { useTheme } from "vuetify";

import { defineComponent } from "vue";
import { Buffer } from "buffer";

export default defineComponent({
  name: "Settings-Component",
  setup() {
    const userDataStore = useUserDataStore();
    const theme = useTheme();
    return { userDataStore, theme };
  },
  data() {
    return {
      pwResetPositive: false,
      settingsTab: false,
      pwResetNegative: false,
      preferencesforum: false,
      preferencescalendar: false,
      preferencesanmeldungen: false,
      preferencesabmeldungen: false,
      permissionSet: false,
    };
  },
  computed: {
    isDarkMode() {
      return this.theme.global.name.value == "dark";
    },
  },
  mounted() {
    this.updatePermissionset();
  },
  methods: {
    async updatePermissionset() {
      const registration = await navigator.serviceWorker.ready;
      this.permissionSet =
        Notification.permission == "granted" &&
        registration.pushManager.getSubscription() != null;
    },
    urlB64ToUint8Array(base64String: string) {
      // Füge Polsterung hinzu, um die Länge auf ein Vielfaches von 4 zu bringen
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

      // Dekodiere die Base64-Zeichenkette in ein Buffer-Objekt
      const rawData = Buffer.from(base64, "base64");

      // Erzeuge ein Uint8Array aus dem Buffer
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData[i];
      }
      return outputArray;
    },

    async askPermissions() {
      const result = await Notification.requestPermission();
      console.log(result);
      if (result == "granted") {
        this.updatePermissionset();
        const registration = await navigator.serviceWorker.ready;
        const applicationServerKey = this.urlB64ToUint8Array(
          "BHrnrFSIHezsOP7XjK2eNN9OmEymb448Heg2HKaxjSfNa3kJpIUUQl0Bo1aEMXoGLCVgK6fSsgU146OY20t8lXE"
        );
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey,
        });
        fetch("https://prima.oldenburgensis.de/api/notifications/subscribe", {
          method: "POST",
          body: JSON.stringify({
            subscription,
          }),
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${this.userDataStore.getToken}`,
          },
        });
      }
    },
    async setNotificationPreferences() {
      try {
        const result = await axios({
          url: `${process.env.VUE_APP_HOST}/notifications/preferences`,
          method: "POST",
          headers: {
            authorization: `Bearer ${this.userDataStore.getToken}`,
          },
          data: {
            forum: this.preferencesforum,
            calendar: this.preferencescalendar,
            anmeldungen: this.preferencesanmeldungen,
            abmeldungen: this.preferencesabmeldungen,
          },
        });
        this.getNotificationPreferences();
      } catch (error) {
        console.error(error);
      }
    },
    async getNotificationPreferences() {
      try {
        const result = await axios({
          url: `${process.env.VUE_APP_HOST}/notifications/preferences`,
          method: "GET",
          headers: {
            authorization: `Bearer ${this.userDataStore.getToken}`,
          },
        });
        this.preferencesforum = !!result.data.forum;
        this.preferencescalendar = !!result.data.calendar;
        this.preferencesanmeldungen = !!result.data.anmeldungen;
        this.preferencesabmeldungen = !!result.data.abmeldungen;
      } catch (error) {
        console.error(error);
      }
    },
    toggleTheme() {
      this.theme.global.name.value =
        this.theme.global.name.value == "dark" ? "light" : "dark";
      this.userDataStore.toggleTheme();
    },
    async passwortZuruecksetzten() {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_HOST}/auth/requestReset`,
          headers: { authorization: `Bearer ${this.userDataStore.getToken}` },
          data: { email: this.userDataStore.getUser.email },
        });
        this.pwResetPositive = true;
      } catch (error) {
        console.log(error);
        this.pwResetNegative = true;
      }
    },
  },
});
</script>
