<template>
  <hello-world />
</template>

<script lang="ts">
import { useUserDataStore } from "@/store/userDataStore";
import { defineComponent } from "vue";

// Components
import HelloWorld from "../components/HelloWorld.vue";

export default defineComponent({
  setup: () => {
    const userDataStore = useUserDataStore();
    return { userDataStore };
  },
  data() {
    return {
      test: "",
    };
  },
  computed: {
    user() {
      return this.userDataStore.getUser;
    },
  },
  name: "HomeView",

  components: {
    HelloWorld,
  },
});
</script>
