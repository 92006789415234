<template>
  <v-app class="ma-auto pa-auto">
    <v-layout>
      <v-navigation-drawer
        float
        @mouseleave="rail = true"
        ref="navBarRef"
        :rail="rail"
        permanent
        @mouseenter="rail = false"
        @click="closeNav()"
      >
        <v-img
          :class="rail ? 'ma-3' : 'mx-auto my-3'"
          :src="
            theme.current.value.dark
              ? '/img/Zirkel-white.png'
              : '/img/Zirkel.png'
          "
          nav
          contain
          max-width="80"
        >
        </v-img>

        <v-divider></v-divider>
        <v-list density="compact" nav v-if="!isLoggedIn">
          <v-list-item
            prepend-icon="mdi-home-city"
            title="Home"
            value="home"
            to="/"
          >
          </v-list-item>
        </v-list>
        <v-list density="compact" nav v-if="isLoggedIn">
          <v-list-item
            prepend-icon="mdi-home-city"
            title="Home"
            value="home"
            to="/"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-calendar"
            title="Calendar"
            value="account"
            to="/calendar"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-forum"
            title="Forum"
            value="Forum"
            to="/forum"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-contacts"
            title="Anschriften"
            value="Anschriften"
            to="/anschriften"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-firefox"
            title="Spefüxe"
            value="Spefüxe"
            to="/spefuexe"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-folder"
            title="Dateien"
            value="dateien"
            to="/dateien"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-camera-burst"
            title="Gallery"
            value="Gallery"
            to="/Gallery"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-poll"
            title="Abstimmungen"
            value="Abstimmungen"
            to="/Abstimmungen"
          ></v-list-item>
        </v-list>
        <v-spacer> </v-spacer>
        <v-list nav v-if="isLoggedIn">
          <SettingsTab />
          <v-list-item
            prepend-icon="mdi-logout"
            :title="logoutString"
            @click="logout"
          ></v-list-item>
        </v-list>
        <v-list v-if="!isLoggedIn" nav>
          <v-list-item
            prepend-icon="mdi-login"
            title="Login"
            to="/login"
          ></v-list-item>
        </v-list>
        <v-spacer> </v-spacer>
        <v-list v-if="userDataStore.getId == '1'">
          <v-list-item disabled>Admin Area</v-list-item>
          <v-list-item to="/Zeitstrahl">Zeitstrahl</v-list-item>
          <v-list-item to="/Familien">Familien</v-list-item>
          <v-list-item to="/admin">Admin</v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main><router-view /> </v-main>
      <v-tooltip>
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            class="text-end ma-5"
            v-scroll="onScroll"
            v-show="fab"
            @click="toTop"
            position="fixed"
            location="bottom right"
            icon="mdi-arrow-up"
            style="z-index: 2000"
            color="primary"
            elevation="8"
          ></v-btn
        ></template>
        <div v-show="fab">Zurück nach oben</div>
      </v-tooltip>
    </v-layout></v-app
  >
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useGoTo, useDisplay, useTheme } from "vuetify";
import { useUserDataStore } from "@/store/userDataStore";
import axios from "axios";
import SettingsTab from "@/components/SettingsTab.vue";

export default defineComponent({
  name: "App",
  components: { SettingsTab },
  setup() {
    const goTo = useGoTo();
    const userDataStore = useUserDataStore();
    const { mobile } = useDisplay();
    const theme = useTheme();
    return { goTo, userDataStore, mobile, theme };
  },
  watch: {
    $route(to, from) {
      this.refresh();
      this.theme.global.name.value = this.userDataStore.getTheme;
    },
  },
  data() {
    return {
      drawer: true,
      fab: false,
      rail: true,
      pwResetPositive: false,
      pwResetNegative: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.userDataStore.isLoggedIn;
    },
    logoutString() {
      return `Als ` + `${this.userDataStore.getBiername}` + ` abmelden`;
    },
  },
  methods: {
    closeNav() {
      if (this.mobile) this.rail = !this.rail;
    },
    async refresh() {
      try {
        if (!this.userDataStore.isLoggedIn) throw new Error("Not logged in");
        const result = await axios({
          url: `${process.env.VUE_APP_HOST}/auth/refresh`,
          method: "POST",
          headers: {
            authorization: "Bearer " + this.userDataStore.getToken,
          },
        });
        const { auth, user } = result.data as RefreshResponse;
        this.userDataStore.setToken(auth.token);
        this.userDataStore.setUser(user);
      } catch (error) {
        console.error(error);
        this.userDataStore.logout();
      }
    },
    logout() {
      this.userDataStore.logout();
      window.location.reload();
    },
    toTop() {
      this.goTo(0);
    },
    onScroll(e: any) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
  },
});

export interface RefreshResponse {
  auth: Auth;
  user: User;
}

export interface Auth {
  token: string;
}

export interface User {
  id: string;
  biername: string;
  adresse: string;
  rights: number;
  geburtsdatum: string;
  email: string;
  telefon: string;
  Vorname: string;
  Nachname: string;
}
</script>
